import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Text from "../common/text";

const Logos = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 50px 16px;
  place-items: center;
`;

const Logo = styled.img`
  height: 80px;
  width: 160px;
  object-fit: contain;
`;

const Clients = ({ logos }) => ( 
  <div>
    <Text
      css={`
        ${({ theme }) => theme.spacing.margin(4, 8, 16, 8)}
      `}
      component="h2"
      font="subtitle"
      size="l"
    >
      Clients
    </Text>
    <Logos>
      {logos.map(({ name, src }) => (
        <Logo src={src} alt={name} />
      ))}
    </Logos>
  </div>
);

Clients.propTypes = {
  /**
   * The array of logos to display, with a src URL and name for each.
   */
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default Clients;
