import PropTypes from "prop-types";
import styled from "styled-components";

const Gutter = styled.div`
  ${({ vertical }) => (vertical ? "width" : "height")}: ${({ size, theme }) =>
    `${size}px`};
  color: blue;
  flex-grow: 1;
`;

Gutter.propTypes = {
  vertical: PropTypes.bool,
  size: PropTypes.number,
};

Gutter.defaultProps = {
  /**
   * The orientation of the gutter.
   */
  vertical: false,
  /**
   * The size of the gutter on an exponential scale to the theme spacing.
   */
  size: 1,
};

export default Gutter;
