import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  height: 30px;
  & > * {
    ${({ theme }) => theme.spacing.padding(0, 2)};
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

const Image = styled.img`
  height: ${({ iconSize }) => `${iconSize * 100}%`};
`;

const Social = ({ socials }) => (
  <Container>
    {socials.map(({ name, iconURL, linkURL, iconSize = 1 }) => (
      <a href={linkURL} target="_blank" rel="noopener noreferrer">
        <Image src={iconURL} alt={name} iconSize={iconSize} />
      </a>
    ))}
  </Container>
);

Social.propTypes = {
  socials: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      iconURL: PropTypes.string.isRequired,
      iconSize: PropTypes.number,
      linkURL: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default Social;
