import styled from "styled-components";

const Body = styled.div`
  max-width: 980px;
  margin: 0 auto;
  ${({ theme }) => theme.spacing.padding(16, 2)};
  ${({ theme }) => theme.media.mobile} {
    ${({ theme }) => theme.spacing.padding(4)};
  }
`;

export default Body;
