import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Header from "../common/header";
import TextSection from "../common/text-section";
import Newsletter from "../common/newsletter";
import Body from "../common/body";
import Text from "../common/text";
import Social from "../common/social";
import Clients from "./clients";
import Gutter from "../common/gutter";

const Footer = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const FooterSection = styled.div`
  height: 180px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 60px;
`;

const Contact = ({
  socials,
  socialTitle,
  address,
  email,
  title,
  subtitle,
  content,
  clients,
}) => (
  <Body>
    <Header />
    <Gutter size={64} />
    <TextSection title={title} subtitle={subtitle}>
      {content}
    </TextSection>
    <Gutter size={32} />
    <Clients logos={clients} />
    <Gutter
      size={5}
      css={`
        ${({ theme }) => theme.media.mobile} {
          height: 48px;
        }
      `}
    />
    <Footer>
      <FooterSection>
        <Text font="subtitle" size="s">
          contacto
        </Text>
        <Text
          font="subtitle"
          css={`
            margin-top: 20px;
          `}
        >
          {address.map((line) => (
            <div>{line}</div>
          ))}
        </Text>
        <Gutter />
        <Text font="subtitle">
          <a href={`mailto:${email}`}>{email}</a>
        </Text>
      </FooterSection>
      <FooterSection>
        <Newsletter />
        <Gutter />
        <div>
          <Text
            font="subtitle"
            size="s"
            css={`
              margin-bottom: 8px;
              display: block;
            `}
          >
            {socialTitle}
          </Text>
          <Social socials={socials} />
        </div>
      </FooterSection>
    </Footer>
  </Body>
);

Contact.propTypes = {
  socials: Social.propTypes.socials,
  socialTitle: PropTypes.string,
  address: PropTypes.arrayOf(PropTypes.string.isRequired),
  email: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.node,
  clients: Clients.propTypes.logos,
};

export default Contact;
