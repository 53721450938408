import React from "react";
import Text from "./text";

const TextSection = ({ supertitle, title, subtitle, children }) => (
  <div>
    {supertitle && (
      <Text
        component="h4"
        font="subtitle"
        size="l"
        css={`
          margin-bottom: 32px;
        `}
      >
        {supertitle}
      </Text>
    )}
    <Text component="h2" font="title" size="xl">
      {title}
    </Text>
    <Text
      component="h3"
      font="subtitle"
      size="l"
      css={`
        margin-bottom: 25px;
      `}
    >
      {subtitle}
    </Text>
    <Text component="div" dangerouslySetInnerHTML={{ __html: children }} />
  </div>
);

export default TextSection;
