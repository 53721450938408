import { graphql } from "gatsby";
import React from "react";
import { withPreview } from 'gatsby-source-prismic'

import SEO from "../components/common/seo";
import ContactComponent from "../components/contact/contact";


const Contact = ({ data }) => {
  const socials = data.prismicSocialNetworks.data.social_networks.map(
    (social) => ({
      name: social.icon.alt || "",
      iconURL: social.icon.fixed.src,
      linkURL: social.url.url,
      iconSize: social.scale || 1,
    })
  );
  const rawInfo = data.prismicContact.data;
  const info = {
    socialTitle: rawInfo.social_title,
    address: rawInfo.address.map(({ line }) => line),
    email: rawInfo.email,
    title: rawInfo.title,
    subtitle: rawInfo.subtitle,
    content: rawInfo.content.html,
    clients: rawInfo.clients.map(({ logo }) => ({
      src: logo.fixed.src,
      name: logo.alt,
    })),
  };
  return (
    <>
      <ContactComponent socials={socials} {...info} />
      <SEO title="Contacto" description="Stay lucid and keep on branding." />
    </>
  );
};

export default withPreview(Contact);

export const query = graphql`
  query ContactQuery {
    prismicSocialNetworks {
      id
      data {
        social_networks {
          icon {
            fixed {
              src
            }
            alt
          }
          scale
          url {
            url
          }
        }
      }
    }
    prismicContact {
      id
      data {
        address {
          line
        }
        clients {
          logo {
            fixed {
              src
            }
            alt
          }
        }
        content {
          html
        }
        email
        social_title
        subtitle
        title
      }
    }
  }
`;
