import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

import Brand from "./brand";

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Header = () => (
  <Container>
    <Link to={"/"}>
      <Brand variant="slogan" />
    </Link>
    <Link to={"/contact"}>
      <Brand variant="logo" />
    </Link>
  </Container>
);

export default Header;
